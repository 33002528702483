import { createAsyncThunk} from '@reduxjs/toolkit';
import { axiosPublic } from '../../../../utils/api/axios';
import axios from 'axios';

let cancelToken;

export const fetchTripsBy = createAsyncThunk("homeSlice/fetchTripsBy", async(params,_) =>{

    // Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel()
    }

    cancelToken = axios.CancelToken.source()

    try{
        const res = await axiosPublic(`price/search/?station_origin=${params.stationFrom}&station_delivery=${params.stationTo}&container=${params.containerType}`,{
            method:"Get",
            cancelToken: cancelToken.token,
        });

        const data= res.data;
        return data;

    }catch(error){

          throw(error);

      }


  });

//url should change
export const postBooking = createAsyncThunk("homeSlice/postBooking", async(paramsObject,thunkAPI) =>{

    try{
        const res = await axiosPublic.post(`Sea_Shipping/booking/`,paramsObject);

        const data= res.data;


        return data;

    }catch(error){

        throw(error);

    }
});

export const postAppointment = createAsyncThunk("homeSlice/postAppointment", async(paramsObject,thunkAPI) =>{

    try{
        const res = await axiosPublic.post(`Sea_Shipping/helper/`,paramsObject);

        const data= res.data;


        return data;

    }catch(error){

        throw(error);

    }
});

//contact us :
export const postContactUs = createAsyncThunk("homeSlice/postContactUs", async(paramsObject,thunkAPI) =>{

    try{
        const res = await axiosPublic.post(`Sea_Shipping/contact_us/`,paramsObject);

        const data= res.data;


        return data;

    }catch(error){

        throw(error);

    }
});
